<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form class="modal-card" autocomplete="off" @submit.prevent="onSubmit">
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add office group</h2>
      </div>
      <div class="modal-card-body">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="groupName">Group Name</label>
              <div class="control">
                <input
                  id="groupName"
                  v-model="groupName"
                  class="input"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="office-select">
          <div class="field is-full office-admin-select">
            <label class="label" for="offices">Administrator for</label>
            <h-multi-select
              id="offices"
              open-direction="top"
              property="Enter"
              :options="officeLocations"
              :object-key="'CRV'"
              :object-label-key="'HRV'"
              :initial-values="selectedOffices"
              :option-icon="true"
              @tagChange="onTagChange"
            />
          </div>
        </div>
      </div>

      <div class="modal-card-foot">
        <button type="submit" class="button is-dark">Add office group</button>
        <button type="button" class="button is-white" @click="onClickCancel">
          Cancel
        </button>
      </div>
    </form>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import HMultiSelect from "@/components/general/HMultiSelect";
import { OfficesMixin } from "@/mixins/OfficesMixin";
import { AlertService } from "@/services/AlertService";
import { CacheService } from "@/services/CacheService";
import { ModalService } from "@/services/ModalService";
import { OfficeGroupsService } from "@/services/OfficeGroupsService";

export default {
  name: "AddOfficeGroupModal",
  components: { HMultiSelect },
  mixins: [OfficesMixin],
  data() {
    return {
      groupName: "",
      offices: [],
      officeLocations: [],
    };
  },
  computed: {
    selectedOffices: {
      get() {
        return this.offices?.map((office) => this.cOffice(office));
      },
      set(offices) {
        this.offices = offices.map((office) => this.cOffice(office).toRef());
      },
    },
  },
  async mounted() {
    await this.fetchOfficeLocations();
  },
  methods: {
    ...CacheService.inject(),
    async fetchOfficeLocations() {
      this.officeLocations = await this.getAdministeringOffices();
    },
    onClickClose() {
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    async onSubmit() {
      ModalService.close();
      await OfficeGroupsService.createOne({
        groupName: this.groupName,
        offices: this.offices,
      });
      AlertService.alert(`${this.groupName} was added`);
    },
    onTagChange(value) {
      this.selectedOffices = value;
    },
  },
};
</script>
<style scoped>
.modal-card-body,
.modal-card {
  overflow: visible;
}

.office-select {
  height: 8em;
}
</style>
