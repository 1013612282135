import axios from "axios";

const timeout = 2000;

function toURL(path) {
  return `http://${path}`;
}

export async function printQR(assetId, office) {
  try {
    const response = await axios.post(
      toURL(office.configuration.qrPrinterAddress) + "/print",
      { qrData: "https://heaven.softhouselabs.com/#/asset/qr/" + assetId },
      { timeout: timeout },
    );
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

export async function testQRPrinterConnection(path) {
  try {
    const response = await axios.get(toURL(path) + "/ready", {
      timeout: timeout,
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
}
