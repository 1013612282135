<template>
  <div class="select multiselect-wrapper">
    <div class="multiselect-container">
      <multiselect
        v-model="values"
        :options="selectableOptions"
        :label="objectLabelKey || objectKey"
        :track-by="objectKey"
        :multiple="true"
        :hide-selected="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="placeholder || 'Select an option'"
        :deselect-label="deselectLabel || 'Deselect'"
        :select-label="selectLabel || ''"
        :open-direction="openDirection || 'down'"
        :tag-position="tagPosition || 'bottom'"
        :initial-values="initialValues"
        :group-select="true"
        group-label="label"
        group-value="values"
        @input="onTagChange()"
      >
        <template #option="props">
          <span>
            <span v-if="optionIcon" class="icon">
              <i class="icon">
                <the-icon
                  v-if="isGroup(props)"
                  icon="game-icons:modern-city"
                  size="20"
                />
                <the-icon v-else icon="game-icons:house" size="20" />
              </i>
            </span>
            <span class="m-1">{{ props.option.HRV }}</span>
          </span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import TheIcon from "@/components/general/TheIcon";

export default {
  name: "HMultiSelect",
  components: {
    TheIcon,
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    objectKey: {
      type: String,
      required: true,
    },
    objectLabelKey: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },
    deselectLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    selectLabel: {
      type: String,
      required: false,
      default: undefined,
    },
    openDirection: {
      type: String,
      required: false,
      default: undefined,
    },
    tagPosition: {
      type: String,
      required: false,
      default: undefined,
    },
    valueGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
    groupValueKey: {
      type: String,
      required: false,
      default: undefined,
    },
    initialValues: {
      type: Array,
      required: false,
      default: () => [],
    },
    nonRemovableTag: {
      type: Object,
      required: false,
      default: undefined,
    },
    optionIcon: Boolean,
  },
  data() {
    return {
      values: this.initialValues,
      selectableOptions: this.options,
      groupHashmap: {},
      groupNames: [],
    };
  },
  watch: {
    options: function () {
      this.initializeGroups();
      this.selectableOptions = this.options;
    },
    nonRemovableTag: function () {
      this.setNonRemovableTag();
    },
  },
  mounted() {
    this.initializeGroups();
  },
  methods: {
    initializeGroups() {
      if (this.valueGroups?.length > 0) {
        this.valueGroups.forEach((group) => {
          this.groupHashmap[group.groupName] = group[this.groupValueKey];
          this.groupNames.push({
            [this.objectLabelKey]: group.groupName,
            [this.objectKey]: group.groupName,
            isGroup: true,
          });
        });
        this.selectableOptions = this.selectableOptions.concat(this.groupNames);
      }
      if (this.nonRemovableTag) this.setNonRemovableTag();
    },
    onTagChange() {
      if (this.nonRemovableTag && this.values[0] !== this.nonRemovableTag) {
        this.values.unshift(this.nonRemovableTag);
      }
      let resolveGroups = [];
      this.values.forEach((selected) => {
        if (this.groupHashmap[selected[this.objectKey]]) {
          this.groupHashmap[selected[this.objectKey]].forEach((groupEntry) => {
            if (this.values.indexOf(groupEntry) === -1) {
              resolveGroups.push(groupEntry);
            }
          });
        } else resolveGroups.push(selected);
      });
      this.values = resolveGroups;
      this.$emit("tagChange", this.values);
    },
    setNonRemovableTag() {
      let index = -1;
      this.values.forEach((object, i) => {
        if (object === this.nonRemovableTag) {
          index = i;
        }
      });
      if (index === -1) {
        this.values.unshift(this.nonRemovableTag);
      } else {
        this.values.unshift(this.values.splice(index, 1)[0]);
      }
    },
    isGroup(props) {
      return props.option.isGroup;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "@/assets/variables";

.multiselect__tag {
  background: $sh-darkblue;

  .multiselect__tag-icon:hover {
    background: $sh-blue;
  }
}

.multiselect__option--highlight,
.multiselect__option--highlight:after {
  background: $sh-darkblue;
}

.multiselect__option--selected {
  background: $sh-blue;
  color: $sh-white;

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: $sh-red;
  }
}

.multiselect__select {
  display: none;
}

.multiselect__tags {
  width: 100%;
  padding-right: 33px;
}

.multiselect-wrapper {
  width: 100%;
}

.multiselect-container {
  position: relative !important;
}

.multiselect__content-wrapper /*, .multiselect__element */ {
}
</style>
