/**
 * @class Retailer
 */

import { DocumentResolver } from "@/models/documentModels/DocumentResolver";

export class Retailer extends DocumentResolver {
  /**
   * @param {{
   * id: string;
   * isDeleted: boolean;
   * officeLocation: firestore.DocumentReference<firestore.DocumentData>;
   * name: string;
   * accountName: string;
   * url: string;
   * isActive: boolean
   * createdAt: string;
   * createdByRef: firestore.DocumentReference<firestore.DocumentData>;
   * updatedAt: string;
   * updatedByRef: firestore.DocumentReference<firestore.DocumentData>;
   * }} parameters
   */
  constructor({
    id = "",
    isDeleted = false,
    officeLocation,
    name = "",
    accountName = "",
    url = "",
    isActive = true,
    createdAt,
    createdByRef,
    updatedAt,
    updatedByRef,
  } = {}) {
    super({
      createdAt,
      createdByRef,
      updatedAt,
      updatedByRef,
    });
    this.id = id;
    this.isDeleted = isDeleted;
    this.officeLocation = officeLocation;
    this.name = name;
    this.accountName = accountName;
    this.url = url;
    this.isActive = isActive;
  }
}
