<!-- SnowAnimation.vue -->
<template>
  <div v-if="isChristmasSeason" class="snow-container">
    <div
      v-for="n in snowflakeCount"
      :key="n"
      class="snowflake"
      :style="getRandomStyle()"
    />
    <div v-if="showSanta" class="santa">🎅🛷</div>
  </div>
</template>

<script>
export default {
  name: "HSnowAnimation",
  data() {
    return {
      snowflakeCount: 50,
      showSanta: false,
      santaPosition: -200,
    };
  },
  computed: {
    isChristmasSeason() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      return month === 12 && day >= 1 && day <= 25;
    },
  },
  created() {
    this.initSantaAnimation();
  },
  methods: {
    initSantaAnimation() {
      setInterval(() => {
        if (!this.showSanta) {
          this.showSanta = true;
          setTimeout(() => {
            this.showSanta = false;
          }, 8000); // Hide Santa after animation completes
        }
      }, 30000);
    },
    getRandomStyle() {
      const size = Math.random() * 4 + 2; // 2-6px
      const animationDuration = Math.random() * 3 + 2; // 2-5s
      const startPosition = Math.random() * 100; // 0-100%

      return {
        width: `${size}px`,
        height: `${size}px`,
        left: `${startPosition}%`,
        animationDuration: `${animationDuration}s`,
        animationDelay: `${Math.random() * 5}s`,
      };
    },
  },
};
</script>

<style scoped>
.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
}

.snowflake {
  position: absolute;
  top: -10px;
  border-radius: 50%;
  background: white;
  opacity: 0.8;
  animation: snowfall linear infinite;
}

.santa {
  position: fixed;
  top: 100px;
  font-size: 40px;
  z-index: 101;
  animation: santaFlight 8s linear;
  transform: translateX(-200px);
}

@keyframes santaFlight {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(calc(100vw + 200px));
  }
}

@keyframes snowfall {
  0% {
    transform: translateY(-10px) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
</style>
