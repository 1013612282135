<template>
  <button
    class="button nav-add-button is-success is-inverted"
    title="Add retailer"
    @click="onClickAddRetailer"
  >
    <the-icon icon="icons8:plus" />
  </button>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";
import AddRetailerModal from "@/components/retailers/AddRetailerModal";
import { ModalService } from "@/services/ModalService";

export default {
  name: "AddRetailerButton",
  components: { TheIcon },
  methods: {
    onClickAddRetailer() {
      ModalService.open(AddRetailerModal);
    },
  },
};
</script>
