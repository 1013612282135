<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form
      class="modal-card"
      autocomplete="off"
      @submit.prevent="onSubmitAddResource"
    >
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add resource</h2>
      </div>

      <div class="modal-card-body">
        <div class="field">
          <label class="label" for="resourceManufacturer">Manufacturer</label>
          <div class="control">
            <input
              id="resourceManufacturer"
              ref="manufacturer"
              v-model="resource.manufacturer"
              class="input"
              minlength="2"
              placeholder="e.g. DELL"
              type="text"
              required
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="resourceModel">Model</label>
          <div class="control">
            <input
              id="resourceModel"
              v-model="resource.model"
              class="input"
              minlength="1"
              placeholder="e.g. XPS 15 9500"
              type="text"
              required
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="resourceType">Type</label>
          <div class="control">
            <div class="select">
              <select id="resourceType" v-model="resource.type" required>
                <option value>Select type</option>
                <option
                  v-for="resourceType in resourceTypes"
                  :key="resourceType"
                  :value="resourceType"
                >
                  {{ getHRVForResourceType(resourceType) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card-foot">
        <button type="submit" class="button is-dark">Add resource</button>
        <button type="button" class="button is-white" @click="onClickCancel">
          Cancel
        </button>
      </div>
    </form>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import {
  Resource,
  ResourceType,
  getHRVForResourceType,
} from "@/models/documentModels/Resource";
import { AlertService } from "@/services/AlertService";
import { AuthService } from "@/services/AuthService";
import { ModalService } from "@/services/ModalService";
import { ResourcesService } from "@/services/ResourcesService";
import { UsersService } from "@/services/UsersService";
import { ResourcesUtils } from "@/utils/ResourcesUtils";

export default {
  name: "AddResourceModal",
  data() {
    return {
      resource: new Resource({
        manufacturer: "",
        model: "",
        type: "",
        officeLocation: "",
      }),
      resourceTypes: ResourceType,
    };
  },
  methods: {
    getHRVForResourceType,
    onClickClose() {
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    async onSubmitAddResource() {
      const user = await UsersService.getOne(AuthService.getUserId());
      ModalService.close();

      const newResource = new Resource({
        type: this.resource.type,
        manufacturer: this.resource.manufacturer.trim(),
        model: this.resource.model.trim(),
        officeLocation: user.officeLocation,
      });
      const resourceAlreadyExists =
        ResourcesUtils.checkResourceAlreadyExists(newResource);

      if (resourceAlreadyExists) {
        const message = "Resource already exists";
        AlertService.alert(message);
      } else {
        await ResourcesService.createOne(newResource);
        const message = "Resource was created";
        AlertService.alert(message);
      }
    },
  },
};
</script>
