<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form
      class="modal-card"
      autocomplete="off"
      @submit.prevent="onSubmitAddKey"
    >
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add key</h2>
      </div>

      <div class="modal-card-body create-key-body">
        <div class="field column is-4">
          <h2>Serial Number</h2>
          <div class="control">
            <input
              id="serialNumber"
              v-model="newKey.serialNumber"
              class="input"
              minlength="1"
              required
              placeholder="e.g. CF214"
              type="text"
            />
          </div>
        </div>

        <div class="field column is-4">
          <h2>Location</h2>
          <div class="control">
            <div class="select">
              <select
                id="officeLocation"
                v-model="newKey.officeLocation"
                required
              >
                <option value>Select location</option>
                <option
                  v-for="office in officeLocations"
                  :key="office.id"
                  :value="office"
                >
                  {{ office.HRV }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card-foot">
        <button type="submit" class="button is-dark">Add key</button>
        <button type="button" class="button is-white" @click="onClickCancel">
          Cancel
        </button>
      </div>
    </form>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { OfficesMixin } from "@/mixins/OfficesMixin";
import { Key, KeyStatus } from "@/models/documentModels/Key";
import { AlertService } from "@/services/AlertService";
import { AuthService } from "@/services/AuthService";
import { CacheService } from "@/services/CacheService";
import { KeysService } from "@/services/KeysService";
import { ModalService } from "@/services/ModalService";
import { UsersService } from "@/services/UsersService";

export default {
  name: "AddKeyModal",
  mixins: [OfficesMixin],
  data() {
    return {
      newKey: new Key({
        officeLocation: null,
        serialNumber: "",
        status: KeyStatus.UNASSIGNED,
      }),
      officeLocations: [],
    };
  },
  async mounted() {
    await this.fetchOfficeLocations();
  },
  methods: {
    ...CacheService.inject(),
    async fetchOfficeLocations() {
      this.officeLocations = await this.getAdministeringOffices();
    },
    async onSubmitAddKey() {
      const keysWithSameSerial = await KeysService.getKeysBySerialNumber(
        this.newKey.serialNumber,
      );
      const keyExists =
        keysWithSameSerial.filter(
          (key) => key.officeLocation.id === this.newKey.officeLocation.id,
        ).length > 0;

      if (keyExists) {
        const message = "Key already exists";
        AlertService.alert(message);
      } else {
        await KeysService.createOne(
          new Key({
            ...this.newKey,
            officeLocation: this.newKey.officeLocation.toRef(),
            changedBy: UsersService.getDocById(AuthService.getUserId()),
            changedDate: DateTime.local().toISODate(),
          }),
        );
        const message = "Key was created";
        AlertService.alert(message);
      }
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    onClickClose() {
      ModalService.close();
    },
  },
};
</script>
