<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form class="modal-card" autocomplete="off" @submit.prevent="onSubmit">
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add retailer</h2>
      </div>
      <div class="modal-card-body">
        <div class="field">
          <label class="label" for="name">Name</label>
          <div class="control">
            <input
              id="name"
              ref="name"
              v-model="name"
              class="input"
              minlength="1"
              placeholder="e.g. Dustin"
              type="text"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="officeLocation">Location</label>
          <div class="control">
            <div class="select">
              <select
                id="officeLocation"
                v-model="newOfficeLocation"
                required
                placeholder="Select location"
              >
                <option
                  v-for="office in offices"
                  :key="office.CRV"
                  :value="office"
                >
                  {{ office.HRV }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">Account name</label>
          <div class="control">
            <input
              id="accountName"
              v-model="accountName"
              class="input"
              placeholder="Enter account name used to login on the retailer's site"
              type="text"
            />
          </div>
        </div>

        <div class="field">
          <label class="label" for="url">URL</label>
          <div class="control">
            <input
              id="url"
              ref="url"
              v-model="url"
              class="input"
              placeholder="e.g. https://www.dustin.se/"
              type="url"
            />
          </div>
        </div>
      </div>
      <div class="modal-card-foot">
        <button type="submit" class="button is-dark">Add retailer</button>
        <button type="button" class="button is-white" @click="onClickCancel">
          Cancel
        </button>
      </div>
    </form>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import { OfficesMixin } from "@/mixins/OfficesMixin";
import { RetailersMixin } from "@/mixins/RetailersMixin";
import { AlertService } from "@/services/AlertService";
import { CacheService } from "@/services/CacheService";
import { ModalService } from "@/services/ModalService";
import { RetailersService } from "@/services/RetailersService";
import { UsersService } from "@/services/UsersService";

export default {
  name: "AddRetailerModal",
  mixins: [OfficesMixin, RetailersMixin],
  data() {
    return {
      name: "",
      officeLocation: null,
      accountName: "",
      url: "",
      offices: [],
    };
  },
  computed: {
    newOfficeLocation: {
      get() {
        return this.cOffice(this.officeLocation);
      },
      set(officeObject) {
        this.officeLocation = officeObject.toRef();
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchRestrictedOffices();
    await this.fetchUserOfficeLocation();
    this.isLoading = false;
  },
  methods: {
    ...CacheService.inject(),
    onClickClose() {
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    async fetchRestrictedOffices() {
      this.offices = await this.getAdministeringOffices();

      if (this.offices.length === 0) {
        AlertService.alert("No Offices found");
      }
    },
    async fetchUserOfficeLocation() {
      this.officeLocation = await UsersService.getUserOffice();
    },
    async onSubmit() {
      if (this.doesRetailerExist(false)) {
        AlertService.alert("Retailer already exists");
        return;
      }
      await RetailersService.createOne({
        name: this.name,
        officeLocation: this.officeLocation,
        accountName: this.accountName,
        url: this.url,
      });
      AlertService.alert(`Retailer ${this.name} was added`);
      ModalService.close();
    },
  },
};
</script>
