<template>
  <icon :icon="icon" :style="{ width: `${size}px`, height: `${size}px` }" />
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "TheIcon",
  components: { Icon },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      required: false,
      default: 24,
    },
  },
};
</script>
