export class OfficeConfiguration {
  /**
   * @param {{
   *  qrPrinterAddress: string;
   * }} parameters
   */
  constructor({ qrPrinterAddress = "" } = {}) {
    this.qrPrinterAddress = qrPrinterAddress;
  }
}
