import { render, staticRenderFns } from "./AddOfficeGroupModal.vue?vue&type=template&id=323548d0&scoped=true"
import script from "./AddOfficeGroupModal.vue?vue&type=script&lang=js"
export * from "./AddOfficeGroupModal.vue?vue&type=script&lang=js"
import style0 from "./AddOfficeGroupModal.vue?vue&type=style&index=0&id=323548d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323548d0",
  null
  
)

export default component.exports