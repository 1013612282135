<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <form class="modal-card" autocomplete="off" @submit.prevent="onSubmit">
      <div class="modal-card-head">
        <h2 class="modal-card-title">Add office</h2>
      </div>
      <div class="modal-card-body">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label" for="HRV">Name</label>
              <div class="control">
                <input
                  id="HRV"
                  v-model="HRV"
                  class="input"
                  type="text"
                  @input="onHRVChange"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label" for="CRV">CRV</label>
              <div class="control">
                <input id="CRV" v-model="CRV" class="input" readonly />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card-foot">
        <button type="submit" class="button is-dark">Add office</button>
        <button type="button" class="button is-white" @click="onClickCancel">
          Cancel
        </button>
      </div>
    </form>

    <button
      class="modal-close is-large"
      aria-label="close"
      @click="onClickClose"
    />
  </div>
</template>

<script>
import { AlertService } from "@/services/AlertService";
import { CacheService } from "@/services/CacheService";
import { ModalService } from "@/services/ModalService";
import { OfficesService } from "@/services/OfficesService";

export default {
  name: "AddOfficeModal",
  data() {
    return {
      HRV: "",
      CRV: "",
    };
  },
  methods: {
    onClickClose() {
      ModalService.close();
    },
    onClickCancel() {
      ModalService.close();
    },
    async onSubmit() {
      if (!this.doesExist(this.CRV)) {
        ModalService.close();
        await OfficesService.createOne({
          HRV: this.HRV,
          CRV: this.CRV,
        });
        AlertService.alert(`Office ${this.HRV.toLowerCase()} was added"`);
      } else {
        AlertService.alert("Office already exists");
      }
    },
    onHRVChange() {
      this.CRV = this.HRV.toUpperCase()
        .normalize("NFKD")
        .replace(/[\u0300-\u036F]/g, "")
        .replace(/\W/g, "");
    },
    doesExist(CRV) {
      return CacheService.getOffices().find((office) => office.CRV === CRV);
    },
  },
};
</script>
