import { getISODate } from "@/utils/DateUtils";
import { jetbrainsLicenseCanBeRevoked } from "@/utils/JetbrainsAssetsUtils";

/**
 * @type {{UNASSIGNED: string, ASSIGNED: string}}
 */
export const JetbrainsAssetStatus = {
  ASSIGNED: "ASSIGNED",
  UNASSIGNED: "UNASSIGNED",
};

/**
 * Returns the Human Readable Value(HRV) for a given status.
 *
 * @param {string} status
 */
export function getHRVForJetbrainsAssetStatus(status) {
  const values = {
    [JetbrainsAssetStatus.ASSIGNED]: "Assigned",
    [JetbrainsAssetStatus.UNASSIGNED]: "Unassigned",
  };
  return values[status];
}

export class JetbrainsAsset {
  /**
   * @type {string}
   */
  id;

  /**
   * @type {boolean}
   */
  isDeleted;

  /**
   * @type {string}
   */
  name;

  /**
   * @type {firestore.DocumentReference<firestore.DocumentData>}
   */
  userRef = "";

  /**
   * @type {string}
   */
  employeeEmail;

  /**
   * @type {string}
   */
  status;

  /**
   * @type {boolean}
   */
  isAutomaticallyRenewed;

  /**
   * @type {string}
   */
  validUntil;

  /**
   * @type {string}
   */
  assignmentDate;

  /**
   * @type {boolean}
   */
  canBeRevoked;

  /**
   * @type {number}
   */
  teamId;

  /**
   * @type {string}
   */
  productCode;

  /**
   * @type {firestore.DocumentReference<firestore.DocumentData>}
   */
  officeLocation = "";

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {firestore.DocumentReference<firestore.DocumentData>}
   */
  createdByRef;

  /**
   * @type {string}
   */
  updatedAt;

  /**
   * @type {firestore.DocumentReference<firestore.DocumentData>}
   */
  updatedByRef;

  /**
   * @param {{
   *  id: string;
   *  product: Object;
   *  assignee: Object;
   *  subscription: Object;
   *  lastSeen: Object;
   *  isAvailableToAssign: boolean;
   * }}
   */
  convertFromResponse({
    licenseId = "",
    product = {},
    assignee = {},
    subscription = {},
    lastSeen = {},
    team = {},
    isAvailableToAssign = false,
  } = {}) {
    const status = isAvailableToAssign
      ? JetbrainsAssetStatus.UNASSIGNED
      : JetbrainsAssetStatus.ASSIGNED;

    this.id = licenseId;
    this.isDeleted = false;
    this.name = product.name;
    this.productCode = product.code;
    this.employeeEmail = assignee?.email ? assignee.email : "";
    this.status = status;
    this.isAutomaticallyRenewed = subscription.isAutomaticallyRenewed;
    this.validUntil = getISODate(subscription.validUntilDate);
    this.assignmentDate = getISODate(lastSeen?.assignmentDate);
    this.canBeRevoked = jetbrainsLicenseCanBeRevoked(
      lastSeen?.lastAssignmentDate,
      status,
    );
    this.teamId = team.id;
  }

  /**
   * @param {Asset} asset
   */
  convertFromAsset(asset) {
    this.id = asset.id;
    this.isDeleted = asset.isDeleted ? asset.isDeleted : false;
    this.name = asset.name;
    this.userRef = asset.userRef;
    this.employeeEmail = asset.employeeEmail;
    this.status = asset.status;
    this.isAutomaticallyRenewed = asset.isAutomaticallyRenewed;
    this.validUntil = asset.validUntil;
    this.canBeRevoked = asset.canBeRevoked;
    this.assignmentDate = asset.assignmentDate;
    this.teamId = asset.teamId;
    this.productCode = asset.productCode;
    this.officeLocation = asset.officeLocation;
    this.createdAt = asset.createdAt;
    this.createdByRef = asset.createdByRef;
    this.updatedAt = asset.updatedAt;
    this.updatedByRef = asset.updatedByRef;
  }

  /**
   * @param {{
   *   id: string;
   *   isDeleted: boolean;
   *   name: string;
   *   userRef: string;
   *   employeeEmail: string;
   *   status: JetbrainsAssetStatus[];
   *   isAutomaticallyRenewed: boolean;
   *   validUntil: string;
   *   canBeRevoked: boolean;
   *   assignmentDate: string;
   *   teamId: number;
   *   productCode: string;
   *   officeLocation: string;
   *   createdAt: string;
   *   createdByRef: firestore.DocumentReference<firestore.DocumentData>;
   *   updatedAt: string;
   *   updatedByRef: firestore.DocumentReference<firestore.DocumentData>;
   * }}
   */
  constructor({
    id = "",
    isDeleted = false,
    name = "",
    userRef = "",
    employeeEmail = "",
    status = ["ASSIGNED", "UNASSIGNED"],
    isAutomaticallyRenewed = false,
    validUntil = "",
    canBeRevoked = false,
    assignmentDate = "",
    teamId = 0,
    productCode = "",
    officeLocation = "",
    createdAt,
    createdByRef,
    updatedAt,
    updatedByRef,
  } = {}) {
    this.id = id;
    this.isDeleted = isDeleted;
    this.name = name;
    this.userRef = userRef;
    this.employeeEmail = employeeEmail;
    this.status = status;
    this.isAutomaticallyRenewed = isAutomaticallyRenewed;
    this.validUntil = validUntil;
    this.assignmentDate = assignmentDate;
    this.canBeRevoked = canBeRevoked;
    this.teamId = teamId;
    this.productCode = productCode;
    this.officeLocation = officeLocation;
    this.createdAt = createdAt;
    this.createdByRef = createdByRef;
    this.updatedAt = updatedAt;
    this.updatedByRef = updatedByRef;
  }
}
