<template>
  <section class="hero" :class="heroClasses">
    <div class="hero-header" />
    <div class="hero-body">
      <div class="container has-text-centered">
        <progress
          class="progress is-medium"
          :class="progressClasses"
          max="100"
        />
        <h2 class="subtitle">{{ loadingPhrase }}</h2>
      </div>
    </div>
    <div class="hero-footer" />
  </section>
</template>

<script>
export default {
  name: "LoadingHero",
  props: {
    isFullheight: {
      type: Boolean,
      default: false,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingPhrases: [
        "Rolling out the red carpet",
        "Cleaning up the steps",
        "Opening the pearly gates",
        "Opening the champagne bottles",
        "Fluffing up the clouds",
      ],
      loadingPhrase: "",
      timer: null,
    };
  },
  computed: {
    heroClasses() {
      return {
        "is-fullheight": this.isFullheight,
        "is-dark": this.isDark,
      };
    },
    progressClasses() {
      return {
        "is-dark": !this.isDark,
        "is-blob": this.isDark,
      };
    },
  },
  created() {
    this.swapLoadingPhrase();
    this.timer = setInterval(this.swapLoadingPhrase, 5000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    swapLoadingPhrase() {
      this.loadingPhrase =
        this.loadingPhrases[
          Math.floor(Math.random() * this.loadingPhrases.length)
        ];
    },
  },
};
</script>
