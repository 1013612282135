import { doc, getFirestore } from "firebase/firestore";
import { CollectionNames } from "@/models/CollectionNames";
import { DocumentResolver } from "@/models/documentModels/DocumentResolver";
import { OfficeConfiguration } from "@/models/documentModels/OfficeConfiguration";

export class Office extends DocumentResolver {
  /**
   * @param {{
   *  id: string;
   *  isDeleted: boolean;
   *  CRV: string;
   *  HRV: string;
   *  configuration: OfficeConfiguration;
   *  createdAt: string;
   *  createdByRef: firestore.DocumentReference<firestore.DocumentData>;
   *  updatedAt: string;
   *  updatedByRef: firestore.DocumentReference<firestore.DocumentData>;
   * }} parameters
   */
  constructor({
    id = "",
    isDeleted = false,
    CRV = "",
    HRV = "",
    configuration = new OfficeConfiguration(),
    createdAt,
    createdByRef,
    updatedAt,
    updatedByRef,
  } = {}) {
    super({
      createdAt,
      createdByRef,
      updatedAt,
      updatedByRef,
    });
    this.id = id;
    this.isDeleted = isDeleted;
    this.CRV = CRV;
    this.HRV = HRV;
    this.configuration = configuration;
  }

  toRef() {
    return doc(getFirestore(), CollectionNames.OFFICE, this.id);
  }
}
