<template>
  <div v-if="isNotificationVisible" id="numberCounter" :class="counterClasses">
    {{ number }}
  </div>
</template>

<script>
import { SupportRequestsFilter as SupportRequestFilterClass } from "@/models/SupportRequestsFilter";
import { SupportRequestStatus } from "@/models/documentModels/SupportRequest";
import { ReactiveService } from "@/services/ReactiveService";
import { SupportRequestsService } from "@/services/SupportRequestsService";
import { SupportRequestsUtils } from "@/utils/SupportRequestsUtils";

export default {
  name: "NumberOfNotifications",
  data() {
    return {
      isNotificationVisible: false,
      circle: true,
      fadeInEnabled: true,
      fadeOutEnabled: false,
      stretchEnabled: false,
      number: 0,
      overrideFunction: false,
      filter: ReactiveService.getReactive(
        "SupportRequestsFilter",
        new SupportRequestFilterClass(),
      ),
      supportRequests: [],
      selector: null,
    };
  },
  computed: {
    counterClasses() {
      return {
        circle: this.circle,
        "fade-in": this.fadeInEnabled,
        "fade-out": this.fadeOutEnabled,
        "stretch-animation": this.stretchEnabled,
      };
    },
  },
  watch: {
    number: function (newNumber, oldNumber) {
      if (newNumber === 0 && oldNumber > 0) {
        this.fadeInEnabled = false;
        this.fadeOutEnabled = true;
        setTimeout(() => {
          this.fadeOutEnabled = false;
          this.isNotificationVisible = false;
        }, 2800);
      }
      if (newNumber > 0) {
        this.isNotificationVisible = true;
        if (oldNumber === 0) {
          this.fadeInEnabled = true;
          setTimeout(() => {
            this.fadeInEnabled = false;
          }, 2000);
        } else if (oldNumber !== newNumber) {
          this.stretchEnabled = true;
          setTimeout(() => {
            this.stretchEnabled = false;
          }, 2000);
        }
      }
    },
  },
  async mounted() {
    this.selector = document.getElementById("numberCounter");
    SupportRequestsService.addObserver(this.updateNotificationNumber);
    setTimeout(() => this.updateNotificationNumber(), 1000);
  },
  destroyed() {
    SupportRequestsService.removeObserver(this.updateNotificationNumber);
  },
  methods: {
    async updateNotificationNumber() {
      this.number = await this.getNumberOfActiveSupportRequests();
    },
    async getNumberOfActiveSupportRequests() {
      await this.fetchSupportRequests();
      return this.supportRequests.filter(
        (request) =>
          request.status === SupportRequestStatus.PENDING ||
          request.status === SupportRequestStatus.CREATED,
      ).length;
    },
    async fetchSupportRequests() {
      this.supportRequests =
        await SupportRequestsUtils.getAllFromSupportRequestsFilter(this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";

.circle {
  height: 20px;
  width: 20px;
  background-color: #e11a04;
  border-radius: 50%;

  position: absolute;
  top: -5px;
  right: 0;

  font-size: 15px;
  color: white;
  text-align: center;
}

.fade-in {
  animation: fade-in;
  animation-duration: 2s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  0% {
    background-color: #e11a04;
  }
  50% {
    height: 25px;
    width: 25px;
    background-color: #e11a04;
    top: -15px;
    box-shadow: #523239;
    font-size: 18px;
    text-align: center;
  }
  100% {
    background-color: #e11a04;
  }
}

.fade-out {
  animation: fade-out;
  animation-duration: 3s;
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.stretch-animation {
  animation: stretch-animation;
  animation-duration: 2s;
}
@keyframes stretch-animation {
  50% {
    height: 25px;
    width: 25px;
    background-color: #e11a04;
    top: -5px;
    box-shadow: #523239;
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: $desktop) {
  .circle {
    height: 20px;
    width: 20px;
    top: 0;
    right: 7px;
    font-size: 15px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
    0% {
      background-color: #e11a04;
    }
    50% {
      height: 25px;
      width: 25px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 18px;
      text-align: center;
    }
    100% {
      background-color: #e11a04;
    }
  }

  @keyframes stretch-animation {
    50% {
      height: 25px;
      width: 25px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 18px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: $tablet) {
  .circle {
    height: 15px;
    width: 15px;
    top: 3px;
    right: 2px;
    font-size: 11px;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }

    0% {
      background-color: #e11a04;
    }
    50% {
      height: 23px;
      width: 23px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 16px;
      text-align: center;
    }
    100% {
      background-color: #e11a04;
    }
  }

  @keyframes stretch-animation {
    50% {
      height: 23px;
      width: 23px;
      background-color: #e11a04;
      top: 0;
      box-shadow: #523239;
      font-size: 16px;
      text-align: center;
    }
  }
}
</style>
