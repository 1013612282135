<template>
  <div class="message" :class="messageClasses">
    <div
      class="message-header"
      :class="messageHeaderClasses"
      @click="onClickMessageHeader"
    >
      <p class>
        <slot name="title" />
      </p>

      <slot name="right-title" />

      <a v-if="isExpandable" href="javascript:" class :aria-label="ariaLabel">
        <span class="icon">
          <the-icon :icon="cardHeaderIcon" size="20" />
        </span>
      </a>
    </div>

    <div v-if="!isExpandable || isExpanded" class="message-body">
      <slot />
    </div>

    <div v-if="hasFooterSlot" class="message-footer">
      <slot name="message-footer" />
    </div>
  </div>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";

export default {
  name: "HMessage",
  components: { TheIcon },
  props: {
    startExpanded: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
    persistKey: {
      type: String,
      default: "",
    },
    isPrimary: Boolean,
    isInfo: Boolean,
    isBlob: Boolean,
    isDark: Boolean,
  },
  data() {
    return {
      isExpanded: this.startExpanded,
    };
  },
  computed: {
    LOCAL_STORAGE_KEY() {
      return this.persistKey ? `HMessage:${this.persistKey}` : "";
    },
    messageClasses() {
      return {
        "is-primary": this.isPrimary,
        "is-info": this.isInfo,
        "is-blob": this.isBlob,
        "is-dark": this.isDark,
      };
    },
    messageHeaderClasses() {
      return {
        "has-cursor-pointer": this.isExpandable,
      };
    },
    ariaLabel() {
      return this.isExpanded ? "Close card" : "Open card";
    },
    cardHeaderIcon() {
      return this.isExpanded
        ? "icon-park-solid:up-one"
        : "icon-park-solid:down-one";
    },
    hasFooterSlot() {
      return this.$slots["message-footer"];
    },
  },
  created() {
    const existingValue = localStorage.getItem(this.LOCAL_STORAGE_KEY);
    if (this.LOCAL_STORAGE_KEY && existingValue) {
      this.isExpanded = existingValue === "true";
    }
  },
  methods: {
    onClickMessageHeader() {
      this.isExpanded = !this.isExpanded;

      this.LOCAL_STORAGE_KEY &&
        localStorage.setItem(this.LOCAL_STORAGE_KEY, String(this.isExpanded));
    },
  },
};
</script>
