import { DateTime } from "luxon";

export function getISODate(date) {
  const newDate = DateTime.fromISO(date).toISODate();
  return newDate ? newDate : "";
}

/**
 * @returns {string}
 */
export function getUtcISONow() {
  return DateTime.utc().toISO();
}
