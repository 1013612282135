<template>
  <button
    class="button nav-add-button is-success is-inverted"
    title="Add key"
    @click="onClickAddKey"
  >
    <the-icon icon="icons8:plus" />
  </button>
</template>

<script>
import TheIcon from "@/components/general/TheIcon";
import AddKeyModal from "@/components/keys/AddKeyModal";
import { ModalService } from "@/services/ModalService";

export default {
  name: "AddKeyButton",
  components: { TheIcon },
  methods: {
    onClickAddKey() {
      ModalService.open(AddKeyModal);
    },
  },
};
</script>
