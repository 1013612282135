import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { CollectionNames } from "@/models/CollectionNames";
import { User } from "@/models/documentModels/User";
import { AuthService } from "@/services/AuthService";
import { getUtcISONow } from "@/utils/DateUtils";

export class FirestoreService {
  /**
   * @template C
   * @param {string} collectionName
   * @param {C} DocClass
   */
  constructor(collectionName, DocClass) {
    this.collectionName = collectionName;
    this.DocClass = DocClass;
    this.observers = [];
    this.isListeningToSnapshot = false;
    this.unsubscribeSnapshotListener = null;
    this.db = getFirestore();
  }

  /**
   *
   * @private
   * @async
   */
  async _setCollectionObserver() {
    const collectionObserverQuery = await this.getAllQuery();
    this.unsubscribeSnapshotListener = onSnapshot(
      collectionObserverQuery,
      () => {
        this.observers.forEach((observer) => observer());
      },
    );
  }

  /**
   *
   * @private
   */
  _removeCollectionObserver() {
    if (this.unsubscribeSnapshotListener) {
      this.unsubscribeSnapshotListener();
      this.unsubscribeSnapshotListener = null;
    }
  }

  /**
   * @function
   * @returns {firestore.CollectionReference<firestore.DocumentData>} collection
   */
  getCollection() {
    return collection(this.db, this.collectionName);
  }

  /**
   * @function
   * @param {string} id
   * @returns {firestore.DocumentReference<firestore.DocumentData>} doc
   */
  getDocById(id) {
    return doc(this.db, this.collectionName, id);
  }

  /**
   * Observe changes in the collection.
   * When the observer is not needed it must be manually removed with the removeObserver() method.
   * @function
   * @param {Function} observer
   */
  addObserver(observer) {
    if (!this.isListeningToSnapshot) {
      this._setCollectionObserver();
      this.isListeningToSnapshot = true;
    }
    this.observers.push(observer);
  }

  /**
   * Remove observer
   * @function
   * @param {Function} observer
   */
  removeObserver(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
    if (!this.observers.length && this.isListeningToSnapshot) {
      this._removeCollectionObserver();
      this.isListeningToSnapshot = false;
    }
  }

  /**
   * Clear all observer
   * @function
   */
  clearObservers() {
    this.observers = [];
    if (this.isListeningToSnapshot) {
      this._removeCollectionObserver();
      this.isListeningToSnapshot = false;
    }
  }

  /**
   * @function
   * @param {firestore.DocumentSnapshot<firestore.DocumentData> | firestore.QueryDocumentSnapshot<firestore.DocumentData>} doc
   * @returns {C} data
   */
  toDocClass(doc) {
    return new this.DocClass({ id: doc.id, ...doc.data() });
  }

  /**
   *
   * @param {firestore.QuerySnapshot<firestore.DocumentData>} resolvedDocs
   */
  toDocClasses(resolvedDocs) {
    const asClasses = [];
    resolvedDocs.docs.forEach((doc) => asClasses.push(this.toDocClass(doc)));
    return asClasses;
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   * @param {string | firestore.FieldPath} fieldPath
   * @param {string} opStr
   * @param {any} value
   */
  async getAllWhere(fieldPath, opStr, value) {
    const q = query(this.getCollection(), where(fieldPath, opStr, value));
    const querySnapshot = await getDocs(q);
    return this.toDocClasses(querySnapshot).filter((doc) => !doc.isDeleted);
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   * @param {Array} whereClauses Array of firestore where clauses to be put into a query
   */
  async getWithWhereClauses(whereClauses) {
    const q = query(this.getCollection(), ...whereClauses);
    const resolveDocs = await getDocs(q);
    return this.toDocClasses(resolveDocs).filter((doc) => !doc.isDeleted);
  }

  /**
   * @returns {firestore.Query<firestore.DocumentData>}
   */
  async getAllQuery() {
    const resolvedDoc = await getDoc(
      doc(this.db, CollectionNames.USER, AuthService.getUserId()),
    );
    const user = new User({ id: resolvedDoc.id, ...resolvedDoc.data() });

    const whereClauses = [];
    if (AuthService.isUser()) {
      whereClauses.push(where("officeLocation", "==", user.officeLocation));
    } else if (AuthService.isOfficeAdmin()) {
      if (user.administeredOffices.length > 1) {
        whereClauses.push(
          where("officeLocation", "in", user.administeredOffices),
        );
      } else {
        whereClauses.push(where("officeLocation", "==", user.officeLocation));
      }
    }
    return query(this.getCollection(), ...whereClauses);
  }

  /**
   * @function
   * @async
   * @returns {Promise<C[]>}
   */
  async getAll() {
    const resolvedDoc = await getDoc(
      doc(this.db, CollectionNames.USER, AuthService.getUserId()),
    );
    const user = new User({ id: resolvedDoc.id, ...resolvedDoc.data() });

    if (AuthService.isUser()) {
      return await this.getAllWhere(
        "officeLocation",
        "==",
        user.officeLocation,
      );
    } else if (AuthService.isOfficeAdmin()) {
      if (user.administeredOffices.length > 1) {
        return await this.getAllWhere(
          "officeLocation",
          "in",
          user.administeredOffices,
        );
      } else {
        return await this.getAllWhere(
          "officeLocation",
          "==",
          user.officeLocation,
        );
      }
    }

    const resolvedDocs = await getDocs(this.getCollection());
    return this.toDocClasses(resolvedDocs).filter((doc) => !doc.isDeleted);
  }

  /**
   * @function
   * @async
   * @param {string} id
   * @returns {Promise<C>} data
   */
  async getOne(id) {
    const resolvedDoc = await getDoc(this.getDocById(id));
    return this.toDocClass(resolvedDoc);
  }

  /**
   * @function
   * @async
   * @param id
   * @param {C} data
   * @returns {Promise<firestore.DocumentReference<firestore.DocumentData>>} doc
   */
  // eslint-disable-next-line no-unused-vars
  async createOne({ id, ...data }) {
    const currentUserRef = doc(
      this.db,
      CollectionNames.USER,
      AuthService.getUserId(),
    );
    const currentTime = getUtcISONow();
    data = {
      ...data,
      updatedAt: currentTime,
      updatedByRef: currentUserRef,
      createdAt: currentTime,
      createdByRef: currentUserRef,
    };
    return await addDoc(this.getCollection(), data);
  }

  /**
   * @function
   * @param {Object[]} objectsToAdd
   */
  async createAll(objectsToAdd) {
    const currentUserRef = doc(
      this.db,
      CollectionNames.USER,
      AuthService.getUserId(),
    );
    const currentTime = getUtcISONow();
    const batch = writeBatch(this.db);
    objectsToAdd.forEach((objectToAdd) => {
      const data = {
        ...objectToAdd,
        updatedAt: currentTime,
        updatedByRef: currentUserRef,
        createdAt: currentTime,
        createdByRef: currentUserRef,
      };
      const objectDocumentRef = doc(
        this.db,
        this.collectionName,
        objectToAdd.id,
      );
      batch.set(objectDocumentRef, { ...data });
    });
    await batch.commit();
  }

  /**
   * @function
   * @async
   * @param id
   * @param {C} data
   * @returns {Promise<void>}
   */
  async updateOne({ id, ...data }) {
    const currentUserRef = doc(
      this.db,
      CollectionNames.USER,
      AuthService.getUserId(),
    );
    const currentTime = getUtcISONow();
    const updatedData = {
      ...data,
      updatedAt: currentTime,
      updatedByRef: currentUserRef,
    };
    const batch = writeBatch(this.db);
    batch.set(this.getDocById(id), updatedData);
    await batch.commit();
  }

  /**
   * @function
   * @async
   * @param {string} id
   * @returns {Promise<void>}
   */
  async deleteOne(id) {
    const doc = await this.getOne(id);
    doc.isDeleted = true;
    await this.updateOne(doc);
  }
}
