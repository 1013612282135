import { getDoc } from "firebase/firestore";
import { getUtcISONow } from "@/utils/DateUtils";

/**
 * @class DocumentResolver
 */
export class DocumentResolver {
  /**
   * @param {{
   *  createdAt: string,
   *  createdByRef: firestore.DocumentReference<firestore.DocumentData>,
   *  updatedAt: string,
   *  updatedByRef: firestore.DocumentReference<firestore.DocumentData>,
   * }} parameters
   */
  constructor({
    createdAt = getUtcISONow(),
    createdByRef = null,
    updatedAt = getUtcISONow(),
    updatedByRef = null,
  } = {}) {
    this.createdAt = createdAt;
    this.createdByRef = createdByRef;
    this.updatedAt = updatedAt;
    this.updatedByRef = updatedByRef;
  }

  /**
   * @function
   * @async
   * @template C
   * @template P extends keyof this
   * @param {C} ClassToResolve
   * @param {P} property
   * @returns {Promise<C>} data
   */
  async resolveRef(ClassToResolve, property) {
    /**
     * @type {DocumentSnapshot<DocumentData> | firestore.QueryDocumentSnapshot<firestore.DocumentData>}
     */
    let resolvedRef;
    try {
      resolvedRef = this[property] ? await getDoc(this[property]) : null;
    } catch (e) {
      if (e.name === "FirebaseError" && e.code === "permission-denied") {
        resolvedRef = null;
      } else throw e;
    }
    return resolvedRef
      ? new ClassToResolve({
          id: resolvedRef.id,
          ...resolvedRef.data(),
        })
      : new ClassToResolve();
  }

  /**
   * @function
   * @async
   * @template C
   * @template P extends keyof this
   * @param {C} ClassToResolve
   * @param {P} property
   * @returns {Promise<C>} data
   */
  async resolveListOfRefs(ClassToResolve, property) {
    /**
     * @type {firestore.DocumentSnapshot<firestore.DocumentData>[] | firestore.QueryDocumentSnapshot<firestore.DocumentData>[]}
     */
    const resolvedRefs = await Promise.all(
      this[property].map(async (ref) => getDoc(ref)),
    );

    if (resolvedRefs.length !== 0) {
      return resolvedRefs.map(
        (ref) =>
          new ClassToResolve({
            id: ref.id,
            ...ref.data(),
          }),
      );
    } else {
      return [];
    }
  }
}
